.choices {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.pair-decision {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;

}

.inbetween{
    text-align: center;
    font-family: 'hfont';
    font-size: 35px;
    letter-spacing: 4px;
    margin: 0 20px 0 20px;
}

.pair-decision > button {
    padding: 30px 50px 30px 50px;
    min-width: 35%;
    border-radius: 50px;
    text-align: center;
    font-family: 'pfont';
    font-size: 19px;
    letter-spacing: 1.5px;
    border: 0px;
    box-shadow: 0px 0px 10px #1e1e1e42;
    transition: all ease-in-out 0.2;
    background: rgba(255,255,255,0.2);
    backdrop-filter: blur(14px);
}

.pair-decision > button:hover {
    background: rgba(255,255,255,0.5);
    transition: all ease-in 0.3s;
}

.random > button{
    margin-bottom: 20px;

    border-radius: 50px;
    padding: 15px 40px 15px 40px;
    text-align: center;
    font-family: 'pfont';
    font-size: 18px;
    letter-spacing: 1.5px;
    border: 0px;
    color: white;
    margin: 2px 0 15px 0;
    background: rgba(75, 75, 75, 0.7);
}

.random > button:hover {
    background: rgba(75, 75, 75, 0.9);
    transition: all ease-in 0.3s;
}

.timer {
    margin-top: 10px;
    font-size: 30px;

    text-align: center;
    font-family: 'hfont';
    color: white;

}

.bar {
    height: 5px;
    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 650px) {

    .pair-decision {
        flex-direction: column;
    }

    .inbetween {
        margin: 20px 0 20px 0;
    }

}