@font-face {
    font-family: hfont;
    src: url("/public/Newake-Font-Demo.otf");
}

@font-face {
    font-family: pfont;
    src: url("/public/Agrandir-Grand-Light-300.otf");
}

.container {
    max-width: 100vw;
    min-height: 100vh;

    background-image: url("/public/background.jpg");
    background-position:absolute;
    background-size: cover;
    margin: -8px;
    padding: 0;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);

}

.content {
    width: 80%;

    margin-bottom: 50px;
}

h1 {
    text-align: center;
    align-self: center;
    font-family: 'hfont';
    font-size: 55px;
    letter-spacing: 4px;
    color: white;
}

.flex {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding-top: 5vh;
    width: 70%;

    margin: 0 auto 0 auto;
}

.header {
    font-size: 5rem;
    text-align: center;

    padding-bottom: 30px;

    border-style: solid;
    border-width: 0 0 3px 0;
}

.add-section {
    margin-left: auto;
    margin-right: auto;
}


.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttons-section {
    width: 40%
}

.strategy-select {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
}

.strategy-select > button {
    width: 100%;
    border-radius: 60px;
    padding: 20px;
    text-align: center;
    font-family: 'pfont';
    font-size: 18px;
    letter-spacing: 1.5px;
    border: 0px;
    color: white;
    margin: 2px 0 2px 0;
    background: rgba(75, 75, 75, 0.6);
}

.strategy-select > button:hover {
    background: rgba(32, 32, 32, 0.6);
    transition: all ease-in 0.3s;
}

.option{
    margin: 4px auto 4px auto;
    font-family: 'pfont';
    font-size: 19px;
    align-self: center;
}

.options-list {
    min-height: 30vh;
    width: 50%;

    background-color: rgba(255,255,255,0.15);
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.25), 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
}

.light_text{
    text-align: center;
    font-family: 'pfont';
    font-size: 30px;
    letter-spacing: 0px;
}

.coloumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.highlighted_box {
    border-radius: 20px;
    font-family: 'hfont';

    min-width: 60%;
    padding: 30px 20% 30px 20%;

    background-color: white; /* Green */
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 40px;
    letter-spacing: 1.5px;
    background: rgba(255,255,255,0.2);
    backdrop-filter: blur(14px);
    box-shadow: 0px 0px 20px #1e1e1e42;

    animation: zoom 1s ease 1;
}

@keyframes zoom {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.text {
    font-family: 'pfont';
    font-size: 30px;
    letter-spacing: 1.5px;
    color: white;
    text-align: center;

    margin-bottom: 100px;
}

.text-box {
    background-color: green;
}

.back {
    border-radius: 20px;
    font-family: 'pfont';
    background-color: white; /* Green */
    border: none;
    color: white;
    padding: 15px 15%;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    letter-spacing: 1.5px;
    background: rgba(75, 75, 75, 0.6);
    backdrop-filter: jblur(14px);
    box-shadow: 0px 0px 20px #1e1e1e;
    
}

.back:hover {
    background: rgba(75, 75, 75, 0.9);
    transition: all ease 0.3s;
}

.info.text {

    font-size: 20px;
    max-width: 80vw;
}

@media screen and (max-width: 650px) {
    .horizontal {
       flex-direction: column-reverse; 
       justify-content: flex-start;
       align-items: center;
    
    }

    .flex {
        width: 90%;
    }

    .options-list {
        width: 100%;
        margin-bottom: 30px;
    }

    .buttons-section {
        width: 100%;
    }

    .strategy-select {
        width: 85%;

        margin-left: auto;
        margin-right: auto;
    }

}
